<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          color="error"
      >

        <span v-if="mode == 'multi'">{{ $t('generic.lang_generateForSelected') }} &nbsp;</span>
        <v-icon>input</v-icon>
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>{{ $t('accounting.lang_generateCollectiveInvoice') }}</v-card-title>
      <v-card-text>
        <BaseDateInput v-model="collectiveServiceDate"
                      :label="$t('accounting.lang_performanceDate')"
                      type="date"/>
      </v-card-text>

      <v-card-actions>

        <v-btn :disabled="loading" color="error" text @click="dialog = false">{{$t("generic.lang_cancel")}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" :loading="loading" color="primary" text @click="confirmAction">{{ $t("generic.lang_yesPlease") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {mapState} from "vuex";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  name: "CollectiveBillDatatableDialog",

  components: { BaseDateInput },

  props: {
    customer: Object,
    customers: Array,
    date: String,
    startDate:Number,
    endDate:Number,
    mode: String
  },

  data() {
    return {
      collectiveServiceDate: (new Date()).toISOString().substr(0, 10),
      dialog: false,
      loading: false,
    }
  },
  computed: {
    ...mapState([
      'pos'
    ])
  },

  methods: {
    async confirmAction() {
      this.loading = true;


      let custArray = [];

      if (typeof this.customer !== 'undefined' && this.customer !== null) {
        custArray = [];
        custArray.push(this.customer);

      } else {
        custArray = this.customers;
      }


      let self = this;
      let iCnt = 0;
      let length = custArray.length;

      //return true;
      //this.customers.forEach(function(customerItem) {
      for (let i of custArray) {

        iCnt = iCnt + 1;
        let customerItem = i;
        await self.axios.post(ENDPOINTS.POS.SALE.CREATE, {
          mode: "collectiveBillFromDeliverySlips",
          customer: customerItem,
          date: self.date,
          startDate: this.startDate,
          endDate: this.endDate,
          deliveryDate: self.collectiveServiceDate,
          posVersion: "FACTURE",
          saleAction: "CASHOUT",
          progressToken: self.pos.progressToken,
          paymentType: 4,
          customerID: customerItem.customer_id,
        }).then((res) => {
          if (res.data.status === "PROGRESS_TOKEN") {
            // UPDATE PROGRESS TOKEN
            self.$store.commit("pos/setProgressToken", res.data.apiProgressToken);
          } else if (res.data.status === 'SUCCESS') {

            // UPDATE PROGRESS TOKEN
            self.$store.commit("pos/setProgressToken", res.data.apiProgressToken);
            //DISPLAY ONLY LAST ONE SUCCESSFULL
            if (length == iCnt) {
              Events.$emit("showSnackbar", {
                message: self.$t('accounting.lang_collectiveInvoiceWasGenerated'),
                color: "success"
              });
              self.dialog = false;
              self.loading = false;
              custArray = [];
              self.$emit('reloadCollectiveDT');
            }
          } else {
            Events.$emit("showSnackbar", {
              message: self.$t('generic.lang_errorOccurred'),
              color: "error"
            });
            self.dialog = false;
            self.loading = false;
            custArray = [];
          }
        });

      }

    }
  }
}
</script>
